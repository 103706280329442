<template>
	<div>
		<TopNav></TopNav>
		<Header></Header>
	
		<div class="ShopTitle">
			
			<div class="Bg">
				<img src="../../assets/img/shop/titlebg2.png" />
			</div>
			
			<div class="Wrap Title">
				
				<ul>
					
					<div class="Logo" @click="ShopIndex()">
						<img v-if="Shop.Logo.length == 0" src="../../assets/img/shop/logo_default.png" />
						<img v-else :src="Shop.Logo" />
					</div>
					
					<div class="Info">
						<h1  @click="ShopIndex()">{{Shop.Name}}</h1>
						<li  @click="ShopIndex()" v-if="Shop.Description && Shop.Description.length > 0"><i class="el-icon-bell"></i> 店铺介绍:{{Shop.Description}}</li>
					</div>
					

					
					<div class="RightAct">
						<div class="Fav">
							<el-button @click="FavoriteChange()" style="background-color: rgba(254,235,234,1);border-color: rgba(254,235,234,1);color: rgba(248,56,56,1);" size="mini" :icon="IsFavorite ? 'el-icon-star-on':'el-icon-star-off'">
								{{IsFavorite ? '取消收藏':'收藏店铺'}}
							</el-button>
						</div>
						<div class="ShopSearch">
							<span>
								<el-input v-model="SearchKeywords" style="background-color: rgba(255,255,255,0.4);" size="mini"></el-input>
							</span>
							<i>
								<el-button @click="GetShopGoods(1)" type="danger" icon="el-icon-search" size="mini">店内搜索</el-button>
							</i>
						</div>
					</div>
					
					
					
				</ul>
				
			</div>
			
			
		</div>
		
		<div style="margin-top: 20px;">
			
			<div class="Wrap BGWhite GoodsClassification">
				
				<li>
					<div class="Left">
						<span :class="GoodsClassificationId == '' ? 'On':''" @click="SelectClassification('')">店内全部商品</span>
						<span  @click="SelectClassification(item.Id)"  :class="GoodsClassificationId == item.Id ? 'On':''" v-for="(item,itemI) in GoodsClassificationList" :key="itemI">{{item.Name}}</span>
					</div>

				</li>
				
			</div>
			
		</div>
		
		<div class="Wrap ShopGoods">
			
			<div class="GoodsList">
				
				<div class="Goods" v-for="(item,itemI) in GoodsRecommend" :key="itemI" @click="$Jump('/goods/detail/'+item.Id)">
						<img v-if="item.Thumb && item.Thumb.length > 0" :src="item.Thumb" />
						<img v-else src="../../assets/img/goods/thumb_default.png" />
					<li>
						{{item.Title}}
					</li>
					<span class="Price">￥<em>{{item.Price}}</em></span>
					<span class="Compare">购买</span>
					<span class="Cart">
						<i class="el-icon-shopping-cart-full"></i>
						加入购物车</span>
					
					<span class="BR"></span>
				</div>
				
				<div class="Page" v-if="Total > PageSize">
					<el-pagination
					  background
					  layout="prev, pager, next"
					  :total="Total" :page-size="PageSize" :current-page.sync="Page" @current-change="handleCurrentChange">
					</el-pagination>
				</div>
				
				<div class="More" @click="$Jump('/goods/goods_list')">
					<span>发现更多</span>
				</div>
				
			</div>
		</div>
			
		<Footer></Footer>
		
	</div>
	
</template>

<script>
	import {Pagination} from 'element-ui'
	export default {
	  name: 'ShopInfo',
	  data() {
	      return {
			  Page:1,
			  PageSize:20,
			  Total:0,
			  GoodsClassificationId:'',//当前被选中的分组ID
			 GoodsRecommend:[],
			 Shop:{
				 Id:'',
				 Name:'',
				 Logo:'',
				 Description:'',
			 },
			 IsFavorite:false,
			 GoodsClassificationList:[],//本店商品分组
			 SearchKeywords:'',//店内商品截图
	      }
	    },
	  components: {
		  'el-pagination':Pagination
	  },
	  created() {
		  this.Shop.Id = this.$route.params.Id
		  if(this.$route.query.keywords != undefined && this.$route.query.keywords != null){
			  this.SearchKeywords = this.$route.query.keywords
		  }
		this.FavoriteCheck()
		this.GetShop()
		this.GetGoodsClassificationList()
		this.StartSearching(1)
	  },
	  methods:{
		  ShopIndex(){
			  this.SearchKeywords = ''
			  this.GoodsClassificationId = ''
			  this.StartSearching()
		  },
		  handleCurrentChange(e){ 
		  	this.Page = e
		  	this.GetShopGoods(e)
		  },
		SelectClassification(_id){
			if(this.GoodsClassificationId == '' && _id == ''){
				return
			}
			this.GoodsClassificationId = _id
			this.StartSearching()
		},
		FavoriteCheck(){
			if(this.$store.getters.getToken == ''){
				return
			}
			let data = {
				Service:'Shop',
				Class: 'ShopFavorite',
				Action: 'Check',
				ShopId:this.Shop.Id
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					return
				}
				this.IsFavorite = res.Data.IsFavoriteCheck
			
			})
			.catch(function (response) {
				this.$message('网络请求错误')
			})
		},
		FavoriteChange(){
			let data = {
				Service:'Shop',
				Class: 'ShopFavorite',
				Action: 'Add',
				ShopId:this.Shop.Id
			}
			if(this.IsFavorite){
				data.Action = 'Del'
			}
			this.$post(this.$store.getters.getApiHost,data)
			.then((res) => {
				
				if(res.ErrorId != 0){
					this.$message(res.Msg)
					return
				}
				if(this.IsFavorite){
					this.$message('已取消收藏该店铺')
					this.IsFavorite = false
				}else{
					this.$message('店铺收藏成功')
					this.IsFavorite = true
				}
			
			})
			.catch(function (response) {
				this.$message('网络请求错误')
			})
		},
		StartSearching(_page){
			this.GetShopGoods(1)
			this.GetShopGoodsCount()
		},
		  GetShopGoods(_page){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'Goods',
		  			  	Action: 'List',
		  			  	Page:_page,
		  			  	PageSize:20,
						ShopId:this.Shop.Id,
						Title:this.SearchKeywords,
						ClassificationId:this.GoodsClassificationId
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	this.GoodsRecommend = res.Data.GoodsList
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetShopGoodsCount(){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'Goods',
		  			  	Action: 'Count',
						ShopId:this.Shop.Id,
						Title:this.SearchKeywords,
						ClassificationId:this.GoodsClassificationId
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	this.Total = res.Data.Count
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetGoodsClassificationList(){
		  			  let data = {
		  			  	Service:'Goods',
		  			  	Class: 'GoodsClassification',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:20,
						ShopId:this.Shop.Id,
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	this.GoodsClassificationList = res.Data.GoodsClassificationList
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  GetShop(){
		  			  let data = {
		  			  	Service:'Shop',
		  			  	Class: 'Shop',
		  			  	Action: 'Get',
		  			  	Id:this.Shop.Id
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){
		  			  		this.$message(res.Msg)
		  			  		return
		  			  	}
		  			  	this.Shop = res.Data
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  }
	  }
	}
</script>

<style scoped>
	.ShopTitle{
		height: 120px;
		position: relative;
	}
	.ShopTitle .Bg{
		width: 100%;
		height: 120px;
		background-color: rgba(23,134,65,0.06);
		position: absolute;
		text-align: center;
	}
	.ShopTitle .Bg img{
		height: 120px;
	}

	.ShopTitle .Title{
		position: absolute;
		width: 100%;
		height: 120px;
	}
	.ShopTitle .Title ul{
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		align-items: center;
	}
	.ShopTitle .Title .Logo{
		cursor: pointer;
	}
	.ShopTitle .Title .Logo img{
		height: 70px;
		width: 70px;
		border-radius: 35px;
		border:2px solid rgba(255,255,255,0.4)
	}
	
	.ShopTitle .Title .Info{
		margin-left: 10px;
		flex: 1;
	}
	.ShopTitle .Title .Info h1{
		color: #FFFFFF;
		margin: 0px;
		padding: 0px;
		font-size: 25px;
		cursor: pointer;
	}
	.ShopTitle .Title .Info li{
		color: rgba(255,255,255,0.7);
		max-width: 800px;
		cursor: pointer;
	}
	
	.RightAct{
		width: 250px;
		text-align: right;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
	}
	.ShopTitle .Title .ShopSearch{
		display: flex;
		margin-top: 10px;
		width: 100%;
	}
	.ShopTitle .Title .ShopSearch div{
	}
	.ShopTitle .Title .ShopSearch span{
		display: inline-block;
		width: 200px;
		margin-right: 5px;
	}
	
	/*element-ui样式改写  如果其它页面要二次改写，则用穿透 >>> 或者 /deep/  */
	>>> .ShopSearch input.el-input__inner {
	   background-color: rgba(0,0,0,0.2);
	   border-color: rgba(255,255,255,0.4);
	   color: #FFFFFF;
	}
	
	.ShopTitle .Title .Fav{
		width: 100px;
	}
	
	.GoodsClassification{
		padding: 10px 0px;
	}
	.GoodsClassification li{
		padding: 10px 20px;
		font-size: 1.1rem;
		display: flex;
	}
	.GoodsClassification li .Left{
		flex: 1;
	}
	.GoodsClassification li .Left span{
		margin-right: 10px;
		cursor: pointer;
		padding: 10px;
		border-radius: 10px;
	}
	.GoodsClassification li .Left span:hover,.GoodsClassification li span.On{
		background-color: rgba(23,134,65,1);
		color: #FFFFFF;
	}
	
	.ShopGoods{
		margin-top: 20px;
	}
	.GoodsList{
		background-color: #FFFFFF;
		width: 1200px;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 30px;
	}
	.GoodsList .More{
		padding: 40px 0px;
		width: 100%;
		display: block;
		text-align: center;
	}
	.GoodsList .More span{
		width: 200px;
		text-align: center;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
		border:1px solid #D30701;
		display:inline-block;
		color:#D30701;
		cursor: pointer;
	}
	.GoodsList .More span:hover{
		color:#ffffff;
		background:#D30701;
	}
	.Goods{
		width: 200px;
		padding: 30px 20px;
		height: 280px;
		text-align: center;
		position: relative;
		border-bottom: 1px solid rgba(0,0,0,0.06);
	}
	.Goods .BR{
		position: absolute;
		height: 100%;
		width: 1px;
		background-color: rgba(0,0,0,0.06);
		right: 0px;
		top: 0px;
	}
	.Goods:hover{
		cursor: pointer;
	}
	.Goods img{
		width: 200px;
		max-height: 200px;
	}
	.Goods li{
		overflow : hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		color: #666666;
		margin-top: 5px;
		text-align: left;
	}
	.Goods .Price{
		position: absolute;
		height: 20px;
		left: 20px;
		bottom: 30px;
		color: #D30701;
		font-size: 12px;
	}
	.Goods .Price em{
		font-size: 1.4rem;
	}
	.Goods .Compare{
		position: absolute;
		height: 20px;
		right: 20px;
		bottom: 25px;
		width: 50px;
		height: 20px;
		line-height: 20px;
		border-radius: 3px;
		border: 1px solid #D30701;
		color: #D30701;
		cursor: pointer;
	}
	.Goods .Compare:hover{
		background-color: #D30701;
		color: #FFFFFF;
	}
	.Goods .Cart{
		position: absolute;
		height: 20px;
		right: 20px;
		top: 30px;
		width: 120px;
		height: 20px;
		line-height: 20px;
		border-radius: 3px;
		border: 1px solid #D30701;
		color: #D30701;
		cursor: pointer;
		background-color: rgba(255,255,255,0.8);
		display: none;
	}
	.Goods:hover .Cart{
		display: block;
	}
</style>
